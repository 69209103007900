import { DOCUMENT } from '@angular/common';
import { Injectable, inject } from '@angular/core';

import { SiteColors } from '@ygm/common/core/models/chamber/chamber-branding';

/** Enum for color variables. */
enum ColorVariable {
	Primary = '--primary-color',
	PrimaryContrast = '--primary-contrast-color',
	Accent = '--accent-color',
}

/** Service for chamber colors management. */
@Injectable({
	providedIn: 'root',
})
export class ChamberColorsService {

	private readonly document = inject(DOCUMENT);

	/**
	 * Sets chamber color options.
	 * @param chamberColors Chamber's color.
	 */
	public setChamberColorsOptions(chamberColors: SiteColors): void {

		const colorOptions = [
			{ name: ColorVariable.Primary, value: chamberColors.primary },
			{ name: ColorVariable.PrimaryContrast, value: chamberColors.secondary },
			{ name: ColorVariable.Accent, value: chamberColors.alternate },
		];

		colorOptions.forEach(color => this.document.documentElement.style.setProperty(color.name, color.value));
	}
}
